import React from "react"
import { graphql } from 'gatsby'
import { Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { Terminal, Mail, Linkedin, Compass, Home } from 'react-feather';
import ProjectCards from "../components/cards"


const Paragraphs = props => (
  <div>
  {props.items && props.items.map((data, index) => {
    return <p>{data}</p>
  })}
  </div>
)



const Images = props => (
  
  <div>
    {props.items.map((data, index) => {
      return <Img fluid={data.img.childImageSharp.fluid} alt={data.title} style={{ float: "left", marginRight: "1rem", width: 150 }}></Img>
    })}
  </div>
  )

  const FirstImage = props => (
  
    <div>
        <Img fluid={props.items[0].img.childImageSharp.fluid} alt={props.items[0].title} style={{ float: "left", marginRight: "2rem", width: 150 }}></Img>
    </div>
    )


export const query = graphql`
  query($slug: String!) {
    allProjectsYaml {
      nodes {
        title
        summary
        description
        slug
        tech
        begin
        end
        images {
            img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
        } 
      }
    }
    skillsYaml(slug: { eq: $slug }) {
      title
      slug
      description
      tech    
    }
  }
`

const skillTemplate = ({ location, data }) => {
  return (
  <Layout location={location} crumbLabel={data.skillsYaml.title}>
    <SEO title={data.skillsYaml.title} slug={data.skillsYaml.slug} />
    <div className="main-narrow">
      <h2>{data.skillsYaml.title}</h2>
      <Paragraphs items={data.skillsYaml.description} />
      <h2>Relevant projects</h2>
    </div>
  <ProjectCards projects={data.allProjectsYaml.nodes.filter(project => project.tech.includes(data.skillsYaml.tech))}></ProjectCards>

    
  </Layout>
  )
}


export default skillTemplate
